import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Finding the Bug in the System`}</h1>
    <p>{`Digging into ecommerce growth again today.`}</p>
    <p><strong parentName="p">{`An Ecom growth engine has 3 parts:`}</strong></p>
    <ul>
      <li parentName="ul">{`Paid ads`}</li>
      <li parentName="ul">{`A high-converting store (function > fashion usually)`}</li>
      <li parentName="ul">{`Emails to drive sales going forward`}</li>
    </ul>
    <p>{`Checking the data, look at this ROAS over the weekend:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "116px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "159.48275862068962%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAgABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB68bkzXFwsA//xAAaEAACAgMAAAAAAAAAAAAAAAABAhEgABIh/9oACAEBAAEFAindMUQKzT//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAACAwAAAAAAAAAAAAAAAAAAAREwMf/aAAgBAQAGPwKUbX//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREQIVFB/9oACAEBAAE/IVuidoyVod3nfpSoSNVEITp//9oADAMBAAIAAwAAABCsMAz/xAAVEQEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAwEBPxCj/8QAFhEBAQEAAAAAAAAAAAAAAAAAIQAQ/9oACAECAQE/ECN//8QAHxABAAIBAwUAAAAAAAAAAAAAAQARMSFhcRBRkbHB/9oACAEBAAE/EMhZsWbfMcLCAYRfvMYghTYJ7ejdNZhY0g7NykSFcEvzZxKR0aZhY7tmf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "ROAS Google Sheet",
          "title": "ROAS Google Sheet",
          "src": "/static/1933c8fdca9175bd5509de00518c8af0/7a5f9/roas-sheet.jpg",
          "srcSet": ["/static/1933c8fdca9175bd5509de00518c8af0/7a5f9/roas-sheet.jpg 116w"],
          "sizes": "(max-width: 116px) 100vw, 116px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Wtf? Halloween? Election?`}</p>
    <p>{`I look at big drops like this as an opportunity to learn. It’s like when your
toilet gets clogged. Yea, there’s some shit on the floor, but at least in the
process of fixing it you’ll learn how the toilet system works.`}</p>
    <p>{`So now, I put on my engineering cap and started to debug this shit.`}</p>
    <p><strong parentName="p">{`Where is this broken?`}</strong></p>
    <p>{`A few months ago, we had 6x ROAS and now they’re showing an average 0.23 ROAS
from the weekend. Oof.`}</p>
    <p>{`I’m no facebook ads expert, in fact, a lot of you reading this know loads more
about ecommerce & fb ads than me. But I know that business is just high-speed
problem solving - so I know I’ll figure this out quickly.`}</p>
    <p><strong parentName="p">{`The 3 places I’m looking to see what’s broken are:`}</strong></p>
    <p><strong parentName="p">{`CPM`}</strong></p>
    <p>{`Is it cost efficient to actually reach the people I want to see my ads?`}</p>
    <p>{`BEFORE = X`}<br parentName="p"></br>{`
`}{`NOW = `}{`$`}{`9.68 CPM`}<br parentName="p"></br>{`
`}{`Seems about right, no big changes there.`}</p>
    <p><strong parentName="p">{`CTR`}</strong><br parentName="p"></br>{`
`}{`Are people actually clicking my ads?`}</p>
    <p>{`Our current CTR is about 2.1%. When were doing 6x ROAS, our CTR was at about 3%,
so this is def a bit worse...but doesn’t explain the full dropoff.`}</p>
    <p><strong parentName="p">{`ADD TO CART`}</strong><br parentName="p"></br>{`
`}{`$5.12/Add to cart before`}<br parentName="p"></br>{`
`}{`$37.05/Add to cart --- clearly this is the issue, it’s costing
us 7.5x more to get an add to cart!!`}</p>
    <p>{`So, now we’ve found where the bug is...but not the bug itself.`}</p>
    <p>{`When did it happen? Unfortunately there’s no clear answer when it changed, but
it looks like sometime in mid-October.`}</p>
    <p><strong parentName="p">{`What could be the issue?`}</strong></p>
    <ul>
      <li parentName="ul">{`Did we change something around that time on the site to make it less high
converting?`}</li>
      <li parentName="ul">{`Did we change our audience, who has lower intent/demand for the product?`}</li>
    </ul>
    <p>{`This is a serious toss-up, it could be either one and there’s probably no
conclusive data that can answer this question. Although, someone smarter than me
on this email list might think differently!`}</p>
    <p>{`So, now i take bias for action and focus on setting up a few new experiments to
see if we can identify where to focus our improvement efforts.`}</p>
    <p><strong parentName="p">{`Experiment 1:`}</strong></p>
    <p>{`Look at all of the audiences we’re running and scale down all of the ones that
are above `}{`$`}{`15/add to cart`}</p>
    <p><strong parentName="p">{`Experiment 2:`}</strong></p>
    <p>{`Set up a new landing page to drive traffic to with 1-click add to cart instead
of the flow that exists now (multi-step).`}</p>
    <p>{`Some of the best converting landing pages I’ve seen make it really easy to add
to your cart and are mobile optimized, like the one below:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1125px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "214.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBAL/xAAXAQEBAQEAAAAAAAAAAAAAAAABAgAD/9oADAMBAAIQAxAAAAGycZh1ihlM+q6OihgyhVTlEGk2/8QAHBAAAgMAAwEAAAAAAAAAAAAAAQIAAxEQEhMx/9oACAEBAAEFAtmmdgOLmwD4blETvbYowLUirDLLLVPtdFLMvP8A/8QAGREAAgMBAAAAAAAAAAAAAAAAABESIFFh/9oACAEDAQE/AUPhLKf/xAAXEQADAQAAAAAAAAAAAAAAAAAQEiBR/9oACAECAQE/AQux/8QAIhAAAQIEBwEAAAAAAAAAAAAAAQACEBEgkQMSITE0QVHh/9oACAEBAAY/AqBrDq6c47QEgLL5CTcEuHs1xnXQLm5T5R//xAAeEAEAAgIDAAMAAAAAAAAAAAABABEhMRBBUWFxsf/aAAgBAQABPyE8T0/J4HgCYKyyEhO4Aoy76PqVYQQwjaVlXtx8QKcSl/GlJ4QolTe1R1DLx//aAAwDAQACAAMAAAAQUzazSB//xAAaEQEAAwADAAAAAAAAAAAAAAABABEhEDFB/9oACAEDAQE/ELeEtCDrsFdeLn//xAAaEQEAAgMBAAAAAAAAAAAAAAABABEQIVEx/9oACAECAQE/EL7KOwT6iBoxU//EACEQAQEAAgEEAgMAAAAAAAAAAAERACExQVGBwRDhYZGh/9oACAEBAAE/EI3rWtL7yW0TrPtlEvd6fv4iUMB+Kb/mNwgmscWnZHrCRGlYwHR3YCqzrJirbyZN5uagGO0PhuDKpLvnBJWIUK8knTE0U+J6wAj1WvLNE4+SfrACw5z/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hydrant",
          "title": "Hydrant",
          "src": "/static/807ec4a7be0a248e3417ed69b7887b30/551ac/hydrant-example.jpg",
          "srcSet": ["/static/807ec4a7be0a248e3417ed69b7887b30/a6407/hydrant-example.jpg 400w", "/static/807ec4a7be0a248e3417ed69b7887b30/a24e6/hydrant-example.jpg 800w", "/static/807ec4a7be0a248e3417ed69b7887b30/551ac/hydrant-example.jpg 1125w"],
          "sizes": "(max-width: 1125px) 100vw, 1125px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now, it’s off to launch these experiments!`}</p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <p><strong parentName="p">{`P.S. Here's a few tabs I've had open that i thought you might enjoy:`}</strong></p>
    <ul>
      <li parentName="ul">{`The All-In Podcast with Chamath, David Sacks, Jason Calacanis, and David
Friedberg is one of my favorite pods to listen to (they're smart as hell) and
they're hosting an
`}<a parentName="li" {...{
          "href": "https://www.allinpodcast.co/live"
        }}><strong parentName="a">{`election show tomorrow night`}</strong></a>{`, might be
fun to hear their live commentary`}</li>
      <li parentName="ul">{`I've told y'all i like reading behind-the-scenes notes,
`}<a parentName="li" {...{
          "href": "https://www.scribd.com/doc/67465758/Sean-Parker-s-Email-to-Spotify-s-Daniel-Ek"
        }}><strong parentName="a">{`this one from Sean Parker to the Spotify founders is money`}</strong></a>{`,
seriously, read it`}</li>
      <li parentName="ul">{`The starbucks mobile app is insane. Customers currently have
$1.27B stored on their cards waiting to be spent. $140M of which will never be
redeemed (breakage). That's means starbucks is effectively borrowing from
customers at a NEGATIVE 10% interest rate!
`}<a parentName="li" {...{
          "href": "https://twitter.com/jp_koning/status/1160387043790413824?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1160387043790413824%7Ctwgr%5Eshare_3&ref_url=http%3A%2F%2Fjpkoning.blogspot.com%2F2019%2F08%2Fstarbucks-monetary-superpower.html"
        }}><strong parentName="a">{`Check the tweet out here`}</strong></a></li>
    </ul>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'https://www.allinpodcast.co/live',
        label: 'All-In Podcast 2020 Election Show'
      }, {
        url: 'https://www.scribd.com/doc/67465758/Sean-Parker-s-Email-to-Spotify-s-Daniel-Ek',
        label: "Sean Parker's Email to Spotify's Daniel Ek"
      }, {
        url: 'https://twitter.com/jp_koning/status/1160387043790413824?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1160387043790413824%7Ctwgr%5Eshare_3&ref_url=http%3A%2F%2Fjpkoning.blogspot.com%2F2019%2F08%2Fstarbucks-monetary-superpower.html',
        label: 'Starbucks Mobile App Tweet'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.firing_lots_of_growth_bullets.label,
      slug: slugify(postMap.firing_lots_of_growth_bullets.slug)
    }} nextButton={{
      text: postMap.the_more_i_know_about_facebook_ads_the_better.label,
      slug: slugify(postMap.the_more_i_know_about_facebook_ads_the_better.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      